import React, { useState } from "react";
import Heading from "../ui/Heading";
import { Col, Container, Image, Row } from "react-bootstrap";
import Round from "../Assets/img/diamond-shape/round-diamond.webp";
import Princess from "../Assets/img/diamond-shape/princess-diamond.webp";
import Cushion from "../Assets/img/diamond-shape/cushion-diamond.webp";
import Emerald from "../Assets/img/diamond-shape/emerald-diamond.webp";
import Oval from "../Assets/img/diamond-shape/oval-diamond.webp";
import Radiant from "../Assets/img/diamond-shape/radiant-diamond.webp";
import Asscher from "../Assets/img/diamond-shape/asscher-diamond.webp";
import Marquise from "../Assets/img/diamond-shape/marquise-diamond.webp";
import Heart from "../Assets/img/diamond-shape/heart-diamond.webp";
import Pear from "../Assets/img/diamond-shape/pear-diamond.webp";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import Slider from "react-slick";
import { Je_welryspelling } from "../../../../Helpers/request";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const data = [
  {
    name: "Round",
    para: "Cut with the optimum light refraction and sparkle.",
    image: Round,
    url: "/diamonds/shape=Round",
  },
  {
    name: "Princess",
    para: "A contemporary cut, with optimal fire and brilliance.",
    image: Princess,
    url: "/diamonds/shape=Princess",
  },
  {
    name: "Cushion",
    para:
      "With soft corners, a beautiful alternative to a round or princess cut.",
    image: Cushion,
    url: "/diamonds/shape=Cushion",
  },
  {
    name: "Emerald",
    para:
      "This stylish cut creates rectangular facets with great stone's clarity.",
    image: Emerald,
    url: "/diamonds/shape=Emerald",
  },
  {
    name: "Oval",
    para: "Optimises carat, giving the illusion of a larger stone.",
    image: Oval,
    url: "/diamonds/shape=Oval",
  },
  {
    name: "Radiant",
    para: "The most brilliant of the rectangular cut diamonds.",
    image: Radiant,
    url: "/diamonds/shape=Radiant",
  },
  {
    name: "Asscher",
    para: "This unique shape is a square emerald, with linear facets.",
    image: Asscher,
    url: "/diamonds/shape=Asscher",
  },
  {
    name: "Marquise",
    para: "Elongate the finger of the wearer with a look of great grandeur.",
    image: Marquise,
    url: "/diamonds/shape=Marquise",
  },
  {
    name: "Heart",
    para: "A rare cut that is a true testament of your relationship.",
    image: Heart,
    url: "/diamonds/shape=Heart",
  },
  {
    name: "Pear",
    para: "Combining the brilliance and design of the Round and Marquise cuts.",
    image: Pear,
    url: "/diamonds/shape=Pear",
  },
];

const ShopByDiamondShape = () => {
  const history = useHistory();

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  var settings = {
    dots: false,
    infinite: true,
    autoplay:true,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [diamondItem, setDiamondItem] = useState(data[0]);

  return (
    <>
      <section className="shop-diamond-by-shape">
        <Container fluid className="showcase-container">
          <Heading className="pb-0 text-center">
            <h2>Shop Diamonds By Shape</h2>
            {/* <p>Explore  {Je_welryspelling}  Collections by Style and Make a Statement.</p> */}
          </Heading>
<div className="oro_shop_shap_tabs">
{/* <TabContext value={value}>
<TabList onChange={handleChange} aria-label="lab API tabs example" centered className="oro_earth_lab_tab_box">
      <Tab label="Earth Grown" value="1"  className="oro_earth_lab_tab_label oro_border_right"/>
      <Tab label="Lab Grown" value="2" className="oro_earth_lab_tab_label" />
    </TabList>

  <TabPanel value="1"> */}
  <div className="inner-shop-diamond-shape">
              <div className="diamonds-collection">
                <Slider {...settings}>
                  {data.map((item, i) => (
                    <div
                      className="diamond-item cursor-pointer"
                      key={i}
                      onClick={() => history.push(item.url)}
                    >
                      <Image src={item.image} alt={item.name} />
                      <p className={`${item.name === item.name && "name"}`}>
                        {item.name}
                      </p>
                    </div>
                  ))}
                </Slider>
              </div>
          </div>
  {/* </TabPanel>
  <TabPanel value="2">
  <div className="inner-shop-diamond-shape">
              <div className="diamonds-collection">
                <Slider {...settings}>
                  {data.map((item, i) => (
                    <div
                      className="diamond-item cursor-pointer"
                      key={i}
                      onClick={() => setDiamondItem(item)}
                    >
                      <Image src={item.image} alt="Round" />
                      <p className={`${item.name === item.name && "name"}`}>
                        {item.name}
                      </p>
                    </div>
                  ))}
                </Slider>
              </div>
          </div>
  </TabPanel>
  </TabContext> */}
</div>
        
        </Container>
      </section>
      {/* {isMobile ? <img className='p-2 img-fluid' src={divider} alt='' /> : ''} */}
    </>
  );
};

export default ShopByDiamondShape;
