import React from 'react'
import { Col, Container, Image, NavLink, Row } from 'react-bootstrap'
import Appraisals from "../../../Assets/images/banner_appraials.jpg";
import CustomJewel from "../../../Assets/images/banner_customJewelry.jpg";
import GeneralRepair from "../../../Assets/images/banner_generalRepair.jpg";
import laser from "../../../Assets/images/banner_laserInscription.jpg";
import Pearl from "../../../Assets/images/banner_pearlRestringing.jpg";
import Personal from "../../../Assets/images/banner_personalEngraving.jpg";
import { Breadcrumbs, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { metaDetails } from '../../../Reducer/metaReducer';

const ServicesPage = () => {
  return (
    <div className='services__wrapper'>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{metaDetails.title}</title>
            <meta name="description" content={metaDetails.description}></meta>
            <meta name="keywords" content={metaDetails.keywords}></meta>
        </Helmet>
        <Container>
            <Row className='mb-5'>
                <Col className="rcs_breadcrumb m-0 mb-md-2">
                    <Breadcrumbs aria-label="breadcrumb">
                        <NavLink underline="hover" color="inherit" to="/">
                            Home
                        </NavLink>
                        <Typography color="text.primary">Our Services</Typography>
                    </Breadcrumbs>
                </Col>
            </Row>
            <div class="service">
                <Image src={Appraisals} alt="Appraisals" />
                <h2>Appraisals</h2>
                <p>Here at Oro Diamanté, we have experts who can provide appraisals of all kinds including, but not limited to; Fine jewelry, diamonds, gemstones, watches, and more. Our appraisers are both well-educated and experienced, and our accredited gemologist can properly identify and grade diamonds and colored stones.</p>
                <p>If you haven’t had your jewelry appraised we highly recommend you have it documented in detail. Having your pieces professionally appraised will allow you to have a separate insurance covering the full value of your fine jewelry.</p>
            </div>

            <div class="service">
                <Image src={CustomJewel} alt="Custom Jewelry" />
                <h2>Custom Jewelry</h2>
                <p>Our jewelry designers can work with you to create a piece that is incomparably yours. Whether you’re starting from scratch or working with diamonds and gemstones that have been passed down through the family, we can take your dream and make it a reality.</p>
                <p>We’ll help you put your ideas on paper, and our designers can create your piece in the metal of your choice.</p>
                <p>We can also work with your vintage pieces or unworn jewelry turning them into something that you will cherish for years to come.</p>
            </div>
            
            <div class="service">
                <Image src={GeneralRepair} alt="General Repair" />
                <h2>General Repair</h2>
                <p>Our commitment to building trust and dependability with our customers includes being there beyond the sale. Whether you need a new clasp on a bracelet, a ring sized, or a diamond set, you can always count on us to assist you with your service needs.</p>
                <ul className='mb-5'>
                    <li><strong>Our standard services include:</strong></li>
                    <li>Complimentary cleaning</li>
                    <li>Ring sizing</li>
                    <li>Expert diamond setting</li>
                </ul>
                <ul className='mb-5'>
                    <li><strong>Our repair services include:</strong></li>
                    <li>Expert evaluation and repair</li>
                    <li>Full spectrum metal working</li>
                    <li>Polishing of worn and damaged gems</li>
                    <li>Antique refurbishing</li>
                    <li>Eyeglass frame repair, including titanium frames</li>
                    <li>Silver Refinishing</li>
                    <li>Broken serving pieces in sterling or silverplate, or even resilver antiques</li>
                </ul>

                <p>We work in any metal; platinum, gold, silver, or a combination</p>
            </div>
            
            <div class="service">
                <Image src={laser} alt="Laser Inscription" />
                <h2>Laser Inscription</h2>
                <p>Our laser inscription service not only personalizes your diamonds, gemstones and pearls, but also serves as a very secure form of identification. The miniature inscriptions can only be viewed under magnification providing you with added peace of mind.</p>
            </div>
            
            <div class="service">
                <Image src={Pearl} alt="Pearl Restringing" />
                <h2>Pearl Restringing</h2>
                <p>What makes pearls so popular is not just their simple elegance and classic style, but also their ability to be passed down through family as timeless heirlooms</p>
                <p>Whether a bracelet or a necklace, it’s important not to neglect these precious pieces by having them restrung every five years. Our experts can work with both graduated or multi strands.</p>
            </div>

            <div class="service">
                <Image src={Personal} alt="Personal Engraving" />
                <h2>Personal Engraving</h2>
                <p>We are pleased to offer engraving services to our customers. Personalizing your gift of fine jewelry adds to its significance and meaning. Wedding bands can be personalized either inside or out to permanently celebrate your special day.</p>
                <p>Whether your engraved message includes a monogram, date, poem, special message or a unique symbol, our engravers can work with you to personalize your gift.</p>
                <p>Both machine and hand engraving are available. We can engrave in script or block, in a variety of fonts, languages and symbols.</p>
                <p>Let us help you beautifully commemorate your gift.</p>
            </div>
        </Container>
    </div>
  )
}

export default ServicesPage