import React from "react";
import { Row, Col, Button, Image, Container } from "react-bootstrap";
import Rating from "@mui/material/Rating";
import Review from "../../../../Assets/images/oro-img/google.png";
import TripAdvisor_Logo from "../../../../Assets/images/TripAdvisor_Logo.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import axios from "axios";
import { base_url, postHeader } from "../../../../Helpers/request";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Heading from "../ui/Heading";
import SlickLeft from "../../../../Assets/images/oro-img/c-left.png";
import SlickRight from "../../../../Assets/images/oro-img/c-right.png";
import { isDesktop, isMobile } from "react-device-detect";

const Customer = () => {
  const [customerData, setCustomerData] = React.useState([]);
  React.useEffect(() => {
    GetCustomerRewies();
    console.log("customerdta", customerData);
  }, []);
  const GetCustomerRewies = () => {
    axios
      .get(`${base_url}/common/site_reviews`, {
        headers: postHeader,
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          setCustomerData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    accessibility: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <Image src={SlickLeft} alt="" />,
    nextArrow: <Image src={SlickRight} alt="" />,
    responsive: [
      {
        breakpoint: 1479,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
        },
      },
    ],
  };
const customerDataNew = [
  {
    username:"Lisa T" ,
    review:"St. Maarten is our favorite island in the eastern Caribbean to visit and Oro Diamante has been our favorite jeweler for over 25 years. Sammy and his family and crew always makes you so welcome and he goes out of his way to make you feel like family. He also has the best prices on any jewelry that you may want/need. We love Sammy and everyone that makes Oro Diamante so special. We cannot wait to return.",
    rating:5,
  },
  {
    username:"Russell g" ,
    review:"We had heard about Oro Diamanté from good friends that have been loyal customers/friends with Sammy for years. We have seen the beautiful jewelry they have bought over the years. We finally got to meet Sammy!! He was very warm and welcoming. He made us feel like we have been friends for years. He was very happy to show us anything we had our minds on. He was never pushy. In fact, he encouraged that we take our time…try things on…see what worked and what didn’t. He took his time with us and made us feel like we were his only customers. I never felt rushed to make a decision. He knew his gems! He is very gifted and skilled as well. When I fell in love with my tanzanite ring, he gave us",
    rating:5,
  },
  {
    username:"gordongarfield3803" ,
    review:"Went to look for a ring. Harry was very knowledgeable and extremely helpful. The jewelry store had a huge selection that will have a piece of jewelry for everyone.",
    rating:5,
  },
  {
    username:"jimsE6367DW" ,
    review:"This place is amazing Harry spent a lot of time with us making sure everything was perfect and we were comfortable with our selections. We will be back for sure.",
    rating:5,
  },
  {
    username:"pbhagat83" ,
    review:"Raju is very knowledgeable and guided me every step of the purchase. I am very happy with my purchase and the experience I had with Oro. I plan to purchase more with their help and guidance as well.",
    rating:5,
  },
  
  {
    username:"ryanpD2663OX" ,
    review:"If you are looking into being treated Like Family and getting a fair Price on rings to watches with a low-pressure atmosphere talk to Raju or Sam, they have been talking care of my family for over 20 years,",
    rating:5,
  },
  

]
  return (
    <section className="rcs_customer_say_section">
      <Container fluid className="showcase-container">
        <div className="d-flex align-items-center justify-content-center pb-3">
          <Heading className="text-center p-0">
            <h2>What People Say</h2>
            <p>Create a beautiful, one-of-a-kind representation of your unique love.</p>
          </Heading>
        </div>

        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <div className="rcs_customer_review_slider">
              <Slider className="rcs_customer_say_inner" {...settings2}>
                {customerDataNew?.map((customdata) => (
                  <div className="rcs_customer_review_slide_item">
                    <div className="rcs_customer_review_footer d-flex align-items-center justify-content-center">
                      {/* <div className="name-charAt mr-3">
                        {customdata?.username.charAt(0)}
                      </div> */}
                      <div className="rcs_customer_review_slide_title">
                        <h3 className="text-center">{customdata?.username}</h3>
                        
                      </div>
                    </div>
                  
                    <div className="rcs_customer_slide_text_content mb-4">
                      <p className="text-center">
                        {customdata?.review?.slice(0, 150)}
                        {customdata?.review?.length > 150 ? "..." : ""}
                    

                    <br></br>
                      <Link
                        to="#"
                        onClick={() =>
                          window.open(
                            "https://www.tripadvisor.in/Attraction_Review-g147347-d3685247-Reviews-Oro_Diamante-Philipsburg_Sint_Maarten_St_Martin_St_Maarten.html",
                            "_blank"
                          )
                        }
                      >
                        {" "}
                        Read More{" "}
                      </Link>
                      </p>

                      <div className="oro_ratting_box text-center">
                      <Rating
                          name="half-rating-read"
                          defaultValue={5}
                          precision={customdata?.rating}
                          size="medium"
                          readOnly
                        />

                      </div>
                    </div>
                    

                    <div className="rcs_customer_slide_title_img mb-4">
                      <div className="rcs_customer_review_slide_img text-center w-100 m-auto">
                        <LazyLoadImage
                          src={TripAdvisor_Logo}
                          alt="Google Review Image"
                          onClick={() =>
                            window.open(
                              "https://www.tripadvisor.in/Attraction_Review-g147347-d3685247-Reviews-Oro_Diamante-Philipsburg_Sint_Maarten_St_Martin_St_Maarten.html",
                              "_blank"
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Customer;
