import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import CustomImg from "../../../../Assets/images/oro-img/custom-design-img.webp";
import Heading from "../ui/Heading";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Je_welryspelling } from "../../../../Helpers/request";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CustomDesign = () => {
    const history = useHistory();
  return (
    <section className="custom-design pt-0">
      <Container fluid className="showcase-container">
        <Row className="align-items-center">
         
          <Col md={6}  sm={12} xs={12} className="order-lg-1 order-md-2 orde-sm-2 order-1">
            <Heading className="mb-2 text-center">
              <span>Custom Design</span>
              <p>
              Design your dream jewelry with us! Collaborate with our
experts to create a unique piece that perfectly
reflects your style.
              </p>
            </Heading>

            <div className="text-center">
              <button onClick={()=>history.push("/custom-design")} className="oro_btn_shop_now ">
              Get Started
               </button>
            </div>
          </Col>
          <Col md={6} sm={12} xs={12} className="text-center pl-lg-0 order-lg-2 order-md-1 orde-sm-1 ">
            <Image src={CustomImg} alt="Custom Design Jewellery " />
          </Col>

        </Row>
      </Container>
    </section>
  );
};

export default CustomDesign;
