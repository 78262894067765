import React from 'react'
import { Col, Container, Image, Row } from "react-bootstrap";
import CustomImg from "../../../../Assets/images/oro-img/rolex.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import settingImg from '../.././../../Assets/images/oro-img/start-setting-img.webp'
import settingDiamond from '../.././../../Assets/images/oro-img/start-diamond-img.webp'
import weddingRings from '../.././../../Assets/images/oro-img/wedding-rings.webp'
import fineJewelry from '../.././../../Assets/images/oro-img/fine-jewelry.webp'

const StartwithSettingAndDiamond = () => {
    const history = useHistory()
    const setingData = [
        {
            bg_img: settingImg,
            headding: "Start with a Setting" ,
            text:"Explore our collection of distinctive engagement ring settings",
            btn__url: "/ringsettings" ,
            btn__name: "Select Setting",
        },
        {
            bg_img: settingDiamond,
            headding: "Start with a Diamond" ,
            text:"Explore our extensive range of GIA-certified diamonds to discover your perfect one",
            btn__url: "/diamonds" ,
            btn__name: "Select Diamond",
        },
        {
            bg_img:weddingRings,
            headding: "Wedding Rings" ,
            text:"Symbolize love with stunning wedding bands, beautifully crafted in various metals for your special day.",
            btn__url: "/jewelry/wedding-rings" ,
            btn__name: "Shop Now",
        },
        {
            bg_img: fineJewelry,
            headding: "Fine jewelry" ,
            text:"Perfect for any occasion, our unique diamond and gemstone jewelry collection is stunning and truly breathtaking.",
            btn__url: "/jewelry/fine-jewelry" ,
            btn__name: "Shop Now",
        },
    ]
  return (
    <div className='oro_start_with_Setting_diamond_section'>
      <Container className='showcase-container'>
<Row>
   {setingData.map((item, index)=>(

<Col sm={12} md={6} lg={6}>
<div className='oro_start_box_bg_imgg'  style={{ backgroundImage: `url(${item.bg_img})` }}>
<div className='oro_start_box_bg_imgg_inner'>
<h2>{item.headding}</h2>
<p>{item.text}</p>
<button className='oro_btn_shop_now' onClick={()=> (history.push(item.btn__url))}>{item.btn__name}</button>
</div>
</div>
</Col>
   ))} 
</Row>
      </Container>
    </div>
  )
}

export default StartwithSettingAndDiamond
