import React from "react";
import Heading from "../ui/Heading";
import { Container } from "react-bootstrap";

const Insta = () => {
  return (
    <section className="insta-section">
      <Container fluid className="showcase-container">
        <Heading>
          <h2>Get a Tour of our Social Media</h2>
          <p>
          Follow   
            <a
              href="https://www.instagram.com/orodiamante.sxm/"
              className="text-dark"
              target="_blank"
            >
              <strong>@orodiamante.sxm</strong> on Instagram to get inspired
            </a>
          </p>
        </Heading>
        <iframe src="https://instagram.demobw.live/orodiamante/" frameborder="0" width="100%"
class="insta-iframe"></iframe>
      </Container>
    </section>
  );
};

export default Insta;
