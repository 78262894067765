import React, { useState } from "react";
import { Col, Row, Button, Modal, Form } from "react-bootstrap";
import { useHistory, NavLink } from "react-router-dom";
import { base_url } from "../Helpers/request";
import "react-toastify/dist/ReactToastify.css";
import { isMobileOnly, isTablet } from "react-device-detect";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { CircularProgress, TextField } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import swal from "sweetalert";
import { cartlengthvalue } from "../Reducer/cartReducer";
import { useDispatch, useSelector } from "react-redux";
import { Wishlengthvalue } from "../Reducer/wishlistReducer";
import { loginDrawer, sessionId, Setuser } from "../Reducer/homeReducer";
import { BiUser } from "react-icons/bi";
import { FiEye, FiEyeOff } from "react-icons/fi";

const Login = (props) => {
  const history = useHistory();
  const session_Id = useSelector(
    (state) => state.persistedReducer.home.session_id
  );
  const [errmsg, setErrmsg] = useState(false);
  const [errmsg2, setErrmsg2] = useState(false);
  const [msg, setMsg] = useState("");
  const [userhover, setUserhover] = useState(false);

  /*search code*/
  const [forgotpassword, setForgotpassword] = useState(false);
  //login
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const login = useSelector((state) => state.persistedReducer.home.logindrawer);
  //forgotpass
  const [f_email, setF_email] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewPass, setViewPass] = React.useState(false);
  const dispatch = useDispatch();
  const signin = () => {
    dispatch(loginDrawer(true));
    setForgotpassword(false);
  };
  const handleClicktop = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosetop = () => {
    setAnchorEl(null);
  };
  // useEffect(()=>{
  //     gapi.load('client:auth2', () => {
  //         gapi.client.init({
  //               clientId: "549981789532-4bt0u9a45fujhv35ha0dil32bomr9221.apps.googleusercontent.com",
  //               plugin_name: "chat"
  //           })
  //       })
  //   },[])
  const handlelogout = async () => {
    await dispatch(sessionId(""));
    await dispatch(loginDrawer(false));
    await dispatch(Wishlengthvalue(0));
    await dispatch(cartlengthvalue(0));
    await dispatch(Setuser([]));
    setAnchorEl(null);
    history.push("/");
  };
  const handlesubmitlogin = (e) => {
    setErrmsg(false);
    e.preventDefault();
    setButtonLoading(true);
    axios
      .post(
        base_url + "/user/login",
        { email, password, session_id: session_Id ? session_Id : "" },
        {
          headers: {
            // 'authorization':`Token e222085edc871220cae528152b73c1317ba2addf`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 1) {
          var user = {
            token: response.data.data.token,
            user_id: response.data.data.user_id,
            email: response.data.data.email,
            first_name: response.data.data.first_name,
            last_name: response.data.data.last_name,
            isLogin: true,
          };
          dispatch(cartlengthvalue(response.data.data.total_cart_count));
          dispatch(Wishlengthvalue(response.data.data.total_wish_count));
          dispatch(Setuser(user));
          dispatch(sessionId(""));
          setEmail("");
          setPassword("");
          setButtonLoading(false);
          dispatch(loginDrawer(false));
          // window.location.reload(true);
        } else {
          setButtonLoading(false);
          setErrmsg(true);
          setMsg(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleforgotpassword = (e) => {
    setButtonLoading(true);
    e.preventDefault();
    var data = {
      email: f_email,
    };
    axios
      .post(base_url + "/user/forget_password", data, {
        headers: {
          // 'authorization':`Token e222085edc871220cae528152b73c1317ba2addf`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.status == 1) {
          setButtonLoading(false);
          setF_email("");
          signin();
          return swal(response.data.message, "", "success");
        } else {
          setButtonLoading(false);
          setErrmsg2(true);
          setMsg(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const responseGoogle = (response) => {
    console.log(response);
    const data = {
      first_name: response.profileObj.givenName,
      last_name: response.profileObj.familyName,
      social_id: response.profileObj.googleId,
      login_method: 2,
      email: response.profileObj.email,
      session_id: session_Id ? session_Id : "",
      language: "EN",
      currency: "USD",
    };
    axios
      .post(`${base_url}/user/socialLogin`, data, {
        headers: {
          // 'authorization':`Token e222085edc871220cae528152b73c1317ba2addf`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.status == 1) {
          // window.location.reload(true);
          var user = {
            token: response.data.data.token,
            user_id: response.data.data.user_id,
            email: response.data.data.email,
            first_name: response.data.data.first_name,
            last_name: response.data.data.last_name,
            isLogin: true,
          };
          dispatch(cartlengthvalue(response.data.data.total_cart_count));
          dispatch(Wishlengthvalue(response.data.data.total_wish_count));
          dispatch(Setuser(user));
          dispatch(sessionId(""));
          setEmail("");
          setPassword("");
          setButtonLoading(false);
          dispatch(loginDrawer(false));
        } else {
          setButtonLoading(false);
          setErrmsg(true);
          setMsg(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [buttonLoading, setButtonLoading] = useState(false);

  const user = useSelector((state) => state.persistedReducer.home.userData);
  return (
    <>
      {user?.isLogin ? (
        <>
          <Button
            className="rcs_blank_button rcs_after_login_button "
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClicktop}
          >
            {isMobileOnly ? (
              <span className="rcs_mobile">{user?.first_name[0]}</span>
            ) : isTablet ? (
              <span className="rcs_mobile">{user?.first_name[0]}</span>
            ) : (
              <span className="rcs_mobile">
                {user?.first_name[0]} <KeyboardArrowDownIcon />
              </span>
            )}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            className="rcs_menu_list_item"
            open={Boolean(anchorEl)}
            onClick={handleClosetop}
            disableScrollLock
          >
            <MenuItem disabled>
              <BiUser /> {user?.first_name}
            </MenuItem>
            <MenuItem onClick={() => history.push("/account/myaccount")}>
              My Account
            </MenuItem>
            <MenuItem onClick={handlelogout}>Logout</MenuItem>
          </Menu>
        </>
      ) : isMobileOnly ? (
        <Button
          variant="link"
          className="rcs_before_login p-0"
          id="loginbutton"
          onClick={signin}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_117_218)">
              <g clip-path="url(#clip1_117_218)">
                <path
                  d="M13.4433 9.60623C12.6126 8.77552 11.6211 8.16403 10.5393 7.80002C11.537 7.06262 12.1852 5.87826 12.1852 4.54522C12.1853 2.31468 10.3706 0.5 8.14001 0.5C5.90944 0.5 4.09476 2.31468 4.09476 4.54522C4.09476 5.87829 4.74304 7.06265 5.74075 7.80002C4.65887 8.164 3.66747 8.77549 2.83669 9.60623C1.42016 11.0228 0.640015 12.9062 0.640015 14.9096C0.640015 15.2357 0.90436 15.5 1.23046 15.5H15.0495C15.3756 15.5 15.64 15.2357 15.64 14.9096C15.64 12.9062 14.8599 11.0228 13.4433 9.60623ZM5.27572 4.54522C5.27572 2.96583 6.56062 1.68093 8.14004 1.68093C9.71947 1.68093 11.0044 2.96583 11.0044 4.54522C11.0044 6.12462 9.71947 7.40952 8.14004 7.40952C6.56062 7.40955 5.27572 6.12462 5.27572 4.54522ZM1.84831 14.3191C2.14696 11.1105 4.85475 8.59045 8.14001 8.59045C11.4253 8.59045 14.1331 11.1105 14.4317 14.3191H1.84831Z"
                  fill="#4E1529"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_117_218">
                <rect
                  width="15"
                  height="16"
                  fill="white"
                  transform="translate(0.640015)"
                />
              </clipPath>
              <clipPath id="clip1_117_218">
                <rect
                  width="15"
                  height="15"
                  fill="white"
                  transform="translate(0.640015 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
        </Button>
      ) : (
        <span id="loginbutton" onClick={signin}>
          <svg
            width="19"
            height="19"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_117_218)">
              <g clip-path="url(#clip1_117_218)">
                <path
                  d="M13.4433 9.60623C12.6126 8.77552 11.6211 8.16403 10.5393 7.80002C11.537 7.06262 12.1852 5.87826 12.1852 4.54522C12.1853 2.31468 10.3706 0.5 8.14001 0.5C5.90944 0.5 4.09476 2.31468 4.09476 4.54522C4.09476 5.87829 4.74304 7.06265 5.74075 7.80002C4.65887 8.164 3.66747 8.77549 2.83669 9.60623C1.42016 11.0228 0.640015 12.9062 0.640015 14.9096C0.640015 15.2357 0.90436 15.5 1.23046 15.5H15.0495C15.3756 15.5 15.64 15.2357 15.64 14.9096C15.64 12.9062 14.8599 11.0228 13.4433 9.60623ZM5.27572 4.54522C5.27572 2.96583 6.56062 1.68093 8.14004 1.68093C9.71947 1.68093 11.0044 2.96583 11.0044 4.54522C11.0044 6.12462 9.71947 7.40952 8.14004 7.40952C6.56062 7.40955 5.27572 6.12462 5.27572 4.54522ZM1.84831 14.3191C2.14696 11.1105 4.85475 8.59045 8.14001 8.59045C11.4253 8.59045 14.1331 11.1105 14.4317 14.3191H1.84831Z"
                  fill="#4E1529"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_117_218">
                <rect
                  width="15"
                  height="16"
                  fill="white"
                  transform="translate(0.640015)"
                />
              </clipPath>
              <clipPath id="clip1_117_218">
                <rect
                  width="15"
                  height="15"
                  fill="white"
                  transform="translate(0.640015 0.5)"
                />
              </clipPath>
            </defs>
          </svg>

          {/* <img onMouseOver={() => setUserhover(true)} onMouseLeave={() => setUserhover(false)} className='rcs_wishlist_icon' src={userhover ? User2 : User1} /> */}
        </span>
      )}
      <Modal
        className="rcs_login_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={login}
        onHide={() => dispatch(loginDrawer(false))}
      >
        <Modal.Header>
          <button
            type="button"
            onClick={() => dispatch(loginDrawer(false))}
            class="close"
          >
            <span aria-hidden="true">×</span>
            <span class="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {forgotpassword ? (
            <div className="rcs_appointment_input rcs_login rcs_forgot">
              <Modal.Title id="contained-modal-title-vcenter">
                Forgot Password
              </Modal.Title>
              <div className="rcs_forgot_msg">
                <span>
                  To reset your password, please enter your email address
                </span>
              </div>
              <Form onSubmit={handleforgotpassword}>
                <Row>
                  <Col>
                    <TextField
                      id="outlined-basic"
                      label="Email Address"
                      type="Email"
                      variant="outlined"
                      value={f_email}
                      onChange={(e) => setF_email(e.target.value)}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      className="rcs_fill_button"
                      type="submit"
                      disabled={buttonLoading}
                    >
                      Continue{" "}
                      {buttonLoading ? (
                        <CircularProgress className="rcs_loader" />
                      ) : (
                        ""
                      )}
                    </Button>
                  </Col>
                </Row>
                {errmsg2 ? (
                  <Row>
                    <Col>
                      <div className="rcs_signup_link rcs_signup_link_error">
                        <p style={{ color: "red" }}>{msg}</p>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <Row>
                  <Col>
                    <div className="rcs_signup_link">
                      <p>
                        Remember your password ?{" "}
                        <NavLink
                          to="#"
                          onClick={() => setForgotpassword(false)}
                        >
                          Login
                        </NavLink>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          ) : (
            <div className="rcs_appointment_input rcs_login">
              <Modal.Title id="contained-modal-title-vcenter">
                Login
              </Modal.Title>
              <Form onSubmit={handlesubmitlogin}>
                <Row>
                  <Col>
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      type="email"
                      variant="outlined"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <TextField
                      id="outlined-basic"
                      label="Password"
                      //type="password"
                      variant="outlined"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      type={viewPass ? "text" : "password"}
                    />
                    <span
                      className="passwordShowHide"
                      onClick={() => setViewPass(!viewPass)}
                    >
                      {viewPass ? <FiEye /> : <FiEyeOff />}{" "}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <NavLink
                      className="rcs_login_forgot rcs_forogt_pass"
                      to="#"
                      onClick={() => setForgotpassword(true)}
                    >
                      Forgot Password ?
                    </NavLink>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      className="rcs_fill_button"
                      type="submit"
                      disabled={buttonLoading}
                    >
                      Sign In{" "}
                      {buttonLoading ? (
                        <CircularProgress className="rcs_loader" />
                      ) : (
                        ""
                      )}
                    </Button>
                  </Col>
                </Row>
                {errmsg ? (
                  <Row>
                    <Col>
                      <div className="rcs_signup_link rcs_signup_link_error">
                        <p>{msg}</p>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <Row>
                  <Col>
                    <div className="rcs_signup_link">
                      <p>
                        Don't have an account ?{" "}
                        <NavLink
                          to="/register"
                          onClick={() => dispatch(loginDrawer(false))}
                        >
                          Sign Up
                        </NavLink>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Form>
              <Row>
                <Col>
                  <div className="rcs_google_signup_main">
                    {/* <GoogleLogin
                                        clientId="549981789532-4bt0u9a45fujhv35ha0dil32bomr9221.apps.googleusercontent.com"
                                        buttonText="Sign in with Google"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                        cookiePolicy={'single_host_origin'}
                                    /> */}
                  </div>
                </Col>
              </Row>

              {/* <FacebookLogin
                        appId="1088597931155576"
                        autoLoad={false}
                        fields="name,email,picture"
                        callback={responseFacebook}
                        cssClass="my-facebook-button-class"
                        icon="fa-facebook"
                        /> */}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Login;
