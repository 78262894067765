import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  Image,
  Nav,
  Row,
  Form,
  Dropdown,
  Accordion,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import "../../../../Assets/css/MegaMenu.css";
import logoImage from "../../../../Assets/images/oro-img/oro-logo.png";
import Login from "../../../../Components/Login";
import { useHistory } from "react-router-dom";
import { Drawer } from "@mui/material";
import menuImages from "../../../../Assets/images/oro-img/togle-icon.png";
import {
  Email_details,
  client_name,
  contact_number,
} from "../../../../Helpers/request";
import {
  isDesktop,
  isMobile,
  isMobileOnly,
  isTablet,
} from "react-device-detect";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { BsFillChatFill, BsFillTelephoneFill } from "react-icons/bs";
import { IoMdMail, IoMdContact } from "react-icons/io";
import { img_url } from "../../../../Helpers/request";
import HeaderStyle from "../Assets/megamenu.module.css";
import {
  currencyCode,
  currencyFlag,
  currencyIcon,
  loginDrawer,
} from "../../../../Reducer/homeReducer";

const MegaMenu = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [scrolled, setScrolled] = useState(false);
  const [setDesktopSearch, setShowDesktopSearch] = useState(false);
  const [submenu, setSubmenu] = useState([]);
  const [show, setShow] = useState(false);
  const [topHeader, setTopHeader] = useState(true);
  const [left, setLeft] = useState(false);
  const [hover, setHover] = useState(0);
  const [search_text, setSearch_text] = useState("");
  const menudata = useSelector((state) => state.persistedReducer.home.menu);
  const currencycode = useSelector(
    (state) => state.persistedReducer.home.currencycode
  );
  const currencyflag = useSelector(
    (state) => state.persistedReducer.home.currencyflag
  );
  const currencydata = useSelector(
    (state) => state.persistedReducer.home.currency
  );
  const user = useSelector((state) => state.persistedReducer.home.userData);
  useEffect(() => {
    document.body.className = window.location.pathname;
    return () => {
      document.body.className = "";
    };
  }, [window.location.pathname]);

  useEffect(() => {
    // Clear search if the path is NOT "search/bracelet"
    if (history.location.pathname != `/search/${search_text}`) {
      setSearch_text("");
    }
  }, [history.location.pathname]);

  const [
    isScrollValueMoreThanHeaderHeight,
    setIsScrollValueMoreThanHeaderHeight,
  ] = useState(false);

  const cartlength = useSelector(
    (state) => state.persistedReducer.cart.cartlength
  );
  const wishlistlength = useSelector(
    (state) => state.persistedReducer.wish.whishlistlength
  );
  const toggleDrawer = (open) => {
    setLeft(open);
  };

  useEffect(() => {
    // googleTranslateElementInit()
    const handleScroll = () => {
      setIsScrollValueMoreThanHeaderHeight(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: "en,es,fr,de,iw,ja,zh-CN,sv,tr,ar,hi",
        gaTrack: true,
      },
      "google_translate_element"
    );
  };

  const currencyHandle = async (code, img, icon) => {
    await dispatch(currencyIcon(icon));
    await dispatch(currencyCode(code));
    await dispatch(currencyFlag(img));
    localStorage.removeItem("bw-fancycolordata");
    localStorage.removeItem("bw-gemstonedata");
    localStorage.removeItem("bw-diamonddata");
    localStorage.removeItem("bw-settingdata");
    sessionStorage.removeItem("bw-listfilter");
    sessionStorage.removeItem("bw_f_filter");
    sessionStorage.removeItem("bw_s_filter");
    sessionStorage.removeItem("bw_d_filter");
    sessionStorage.removeItem("bw_g_filter");
    sessionStorage.removeItem("bw-list-category");
    if (window.location.pathname == "/complete-diamond-ring") {
      history.push("/diamonds");
    } else if (window.location.pathname == "/complete-gemstone-ring") {
      history.push("/gemstones");
    } else if (window.location.pathname == "/complete-fancycolor-ring") {
      history.push("/fancy-color-diamond");
    }
    window.location.reload();
  };
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const resizeScreen = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    resizeScreen();
    window.addEventListener("resize", resizeScreen);
    return () => {
      window.removeEventListener("resize", resizeScreen);
    };
  });

  const divRef = useRef(null);
  const [divHeight, setDivHeight] = useState(0);

  useEffect(() => {
    if (menudata.length > 0 && divRef.current) {
      setDivHeight(divRef.current.offsetHeight);
    }
  }, [menudata]);

  const [ringBuiliderClear, setRingBuiliderClear] = useState(false);

  useEffect(() => {
    if (
      ringBuiliderClear === true &&
      JSON.parse(localStorage.getItem("bw-settingdata"))
    ) {
      if (window.location.pathname.includes("/gemstones")) {
        localStorage.removeItem("bw-settingdata");
        sessionStorage.setItem("bw-step", JSON.stringify([2, 3]));
        setRingBuiliderClear(false);
      } else if (window.location.pathname.includes("/fancy-color-diamond")) {
        localStorage.removeItem("bw-settingdata");
        sessionStorage.setItem("bw-step", JSON.stringify([0, 3]));
        setRingBuiliderClear(false);
      } else {
        localStorage.removeItem("bw-settingdata");
        sessionStorage.setItem("bw-step", JSON.stringify([1, 3]));
        setRingBuiliderClear(false);
      }
    }
  }, [ringBuiliderClear]);
  return (
    <>
      <div className="rcs_header_seaction" id="rcs_header_seaction">
        {/* Top Header Section */}
        {topHeader ? (
          isDesktop ? (
            <div
              className={
                "rcs_top_header d-none " + HeaderStyle.Showcase_top_header
              }
            >
              <div className="showcaseversion">
                <p className="text">Showcase Version</p>
                <ul className="versoinlist">
                  <li>
                    <a href="/">Version 1</a>
                  </li>
                  <li>
                    <a href="https://showcase2.demobw.com/">Version 2</a>
                  </li>
                </ul>
              </div>
              <Container
                className={
                  "rcs_custom_home_container " +
                  HeaderStyle.Showcase_custom_home_container
                }
              >
                <div
                  className={
                    "rcs_top_head_content " +
                    HeaderStyle.Showcase_top_head_content
                  }
                >
                  <Row>
                    <Col md={12} className="p-0 text-center">
                      <p className={HeaderStyle.Showcase_top_head_text}>
                        Free Shipping All Over The World
                      </p>
                    </Col>
                    <Col xs={12} md={3} lg={4} className="p-0">
                      <div
                        id="google_translate_element"
                        className="float-right"
                      ></div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}

        {/* Top Header Section */}
        <div style={{ height: divHeight }}>
          <div
            ref={divRef}
            className={
              isScrollValueMoreThanHeaderHeight
                ? "rcs_logo_head rcs_sticky-top sticky-top"
                : "rcs_logo_head"
            }
          >
            {/* Header Section */}

            <div className="rcs_header">
              <Container className="showcase-container">
                {/* Center Header */}
                <div className={HeaderStyle.showcase_center_header}>
                  <Row>
                    {isMobile ? (
                      <Col xs={4} md={4}>
                        <Nav className="m-auto rcs_mobile_header_menu">
                          {/* <Image
                            onClick={() => {
                              toggleDrawer(true);
                              dispatch(loginDrawer(false));
                            }}
                            src={menuImages}
                            alt="menu icon"
                            width="30px"
                          ></Image> */}
                          <svg
                            onClick={() => {
                              toggleDrawer(true);
                              dispatch(loginDrawer(false));
                            }}
                            width="22"
                            height="15"
                            viewBox="0 0 22 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.758558 0C0.339451 0 0 0.335869 0 0.74994C0 1.16402 0.339729 1.49988 0.758558 1.49988H13.6552C14.0743 1.49988 14.4138 1.16401 14.4138 0.74994C14.4138 0.335595 14.0741 0 13.6552 0H0.758558ZM0.758558 6.75006C0.339451 6.75006 0 7.08593 0 7.5C0 7.91407 0.339729 8.24994 0.758558 8.24994H21.2414C21.6606 8.24994 22 7.91407 22 7.5C22 7.08565 21.6603 6.75006 21.2414 6.75006H0.758558ZM0.758558 13.5001C0.339451 13.5001 0 13.836 0 14.2501C0 14.6641 0.339729 15 0.758558 15H13.6552C14.0743 15 14.4138 14.6641 14.4138 14.2501C14.4138 13.8357 14.0741 13.5001 13.6552 13.5001H0.758558Z"
                              fill="#4E1529"
                            />
                          </svg>
                        </Nav>
                      </Col>
                    ) : (
                      <Col md={4} lg={4} className="d-flex align-items-center">
                        <div
                          className={HeaderStyle.showcase_center_header_left}
                        >
                          {/* <ul className="d-none">
                            <li
                              onClick={(e) => {
                                window.location.href = contact_number.link;
                              }}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.60946 10.3936C12.9337 13.7169 13.6878 9.87218 15.8044 11.9873C17.8449 14.0272 19.0177 14.4359 16.4324 17.0205C16.1085 17.2808 14.051 20.4118 6.82018 13.183C-0.411557 5.95325 2.71766 3.89362 2.97798 3.56988C5.56956 0.978123 5.97123 2.15774 8.01175 4.19769C10.1283 6.31366 6.28522 7.07026 9.60946 10.3936Z"
                                  fill="var(--primary)"
                                />
                              </svg>
                              {contact_number.text}
                            </li>
                            <li
                              onClick={(e) => {
                                window.location.href = Email_details.link;
                              }}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14.1162 2.5C15.2337 2.5 16.3087 2.94167 17.0995 3.73417C17.8912 4.525 18.3337 5.59167 18.3337 6.70833V13.2917C18.3337 15.6167 16.442 17.5 14.1162 17.5H5.88366C3.55783 17.5 1.66699 15.6167 1.66699 13.2917V6.70833C1.66699 4.38333 3.54949 2.5 5.88366 2.5H14.1162ZM15.442 7.95L15.5087 7.88333C15.7078 7.64167 15.7078 7.29167 15.4995 7.05C15.3837 6.92583 15.2245 6.85 15.0587 6.83333C14.8837 6.82417 14.717 6.88333 14.5912 7L10.8337 10C10.3503 10.4008 9.65783 10.4008 9.16699 10L5.41699 7C5.15783 6.80833 4.79949 6.83333 4.58366 7.05833C4.35866 7.28333 4.33366 7.64167 4.52449 7.89167L4.63366 8L8.42533 10.9583C8.89199 11.325 9.45783 11.525 10.0503 11.525C10.6412 11.525 11.217 11.325 11.6828 10.9583L15.442 7.95Z"
                                  fill="var(--primary)"
                                />
                              </svg>
                              {Email_details.text}
                            </li>
                          </ul> */}
                          <div className="rcs_logo_section text-center d-lg-block d-xl-block d-none">
                            {/* <NavLink to="/"> */}
                            <Image
                              height="60"
                              onClick={() => history.push("/")}
                              src={logoImage}
                              alt={client_name.c_name}
                            ></Image>
                            {/* </NavLink> */}
                          </div>
                        </div>
                      </Col>
                    )}
                    <Col
                      xs={4}
                      md={4}
                      lg={0}
                      className="d-flex align-items-center justify-content-center d-xl-none d-lg-block d-md-block d-sm-block"
                    >
                      <div
                        className={
                          "mg_menu_logo_main_div " +
                          HeaderStyle.showcase_center_header_center
                        }
                      >
                        <div className="rcs_logo_section text-center">
                          {/* <NavLink to="/"> */}
                          <Image
                            height="60"
                            onClick={() => history.push("/")}
                            src={logoImage}
                            alt={client_name.c_name}
                          ></Image>
                          {/* </NavLink> */}
                        </div>
                      </div>
                    </Col>

                    {isMobile ? (
                      <Col sm={4} xs={4}>
                        <div className="rcs_right_side rcs_right_side_mobile1">
                          <ul>
                            <li>
                              <Login />
                            </li>
                            <li>
                              <div
                                className="showacase_mobile_login  position-relative"
                                onClick={() => toggleDrawer(false)}
                              >
                                <div
                                  className="d-flex justify-content-start align-items-center oro_align"
                                  onClick={() =>
                                    user?.isLogin
                                      ? history.push("/account/wishlist")
                                      : history.push("/wishlist")
                                  }
                                >
                                  <svg
                                    className="rcs_cart_mobile_icon1"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_117_227)">
                                      <g clip-path="url(#clip1_117_227)">
                                        <path
                                          d="M14.7656 2.67589C14.0257 1.83642 12.9994 1.37411 11.8756 1.37411C10.2951 1.37411 9.29447 2.31806 8.73337 3.10995C8.5878 3.31544 8.46396 3.52149 8.35999 3.71537C8.25601 3.52149 8.1322 3.31544 7.9866 3.10995C7.4255 2.31806 6.42487 1.37411 4.84436 1.37411C3.72059 1.37411 2.69423 1.83645 1.95437 2.67592C1.24867 3.47672 0.859985 4.54922 0.859985 5.69584C0.859985 6.94398 1.34728 8.10487 2.39353 9.34916C3.3286 10.4613 4.67382 11.6076 6.23157 12.935C6.81203 13.4297 7.41229 13.9412 8.05134 14.5003L8.07053 14.5171C8.15338 14.5897 8.25668 14.6259 8.35999 14.6259C8.46329 14.6259 8.56659 14.5896 8.64944 14.5171L8.66863 14.5003C9.30768 13.9412 9.90794 13.4297 10.4885 12.935C12.0461 11.6076 13.3914 10.4613 14.3264 9.34916C15.3727 8.10484 15.86 6.94398 15.86 5.69584C15.86 4.54922 15.4713 3.47672 14.7656 2.67589ZM9.91837 12.266C9.41798 12.6924 8.903 13.1313 8.35999 13.6033C7.817 13.1313 7.30199 12.6925 6.80151 12.266C3.75291 9.66815 1.73889 7.95191 1.73889 5.69584C1.73889 4.76332 2.04958 3.89719 2.61375 3.25703C3.1844 2.60959 3.97656 2.25302 4.84436 2.25302C6.04931 2.25302 6.82823 2.99535 7.26944 3.61808C7.66521 4.1766 7.87172 4.73962 7.94215 4.95575C8.00107 5.13665 8.16973 5.25909 8.35999 5.25909C8.55024 5.25909 8.7189 5.13665 8.77782 4.95575C8.84825 4.73962 9.05476 4.1766 9.45053 3.61805C9.89174 2.99535 10.6707 2.25302 11.8756 2.25302C12.7434 2.25302 13.5356 2.60959 14.1062 3.25703C14.6704 3.89719 14.9811 4.76332 14.9811 5.69584C14.9811 7.95191 12.9671 9.66815 9.91837 12.266Z"
                                          fill="#4E1529"
                                        />
                                      </g>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_117_227">
                                        <rect
                                          width="15"
                                          height="16"
                                          fill="white"
                                          transform="translate(0.859985)"
                                        />
                                      </clipPath>
                                      <clipPath id="clip1_117_227">
                                        <rect
                                          width="15"
                                          height="15"
                                          fill="white"
                                          transform="translate(0.859985 0.5)"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  {wishlistlength && wishlistlength != "0" ? (
                                    <span className="rcs_wish_badge new-mob-wish">
                                      {wishlistlength}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </li>
                            <li
                              onClick={() => {
                                history.push("/cart");
                              }}
                            >
                              <svg
                                className="rcs_cart_mobile_icon"
                                width="19"
                                height="19"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_117_235)">
                                  <g clip-path="url(#clip1_117_235)">
                                    <g clip-path="url(#clip2_117_235)">
                                      <path
                                        d="M13.3899 15.007L12.2498 4.15031C12.2259 3.92304 12.0344 3.75046 11.8058 3.75046H9.66303V3.26191C9.66303 1.73905 8.42398 0.5 6.90112 0.5C5.37814 0.5 4.13909 1.73905 4.13909 3.26191V3.75046H1.9963C1.76776 3.75046 1.57619 3.92304 1.55227 4.15031L0.412214 15.007C0.399053 15.1327 0.439909 15.2583 0.52448 15.3523C0.609166 15.4463 0.729787 15.5 0.856244 15.5H12.9458C13.0723 15.5 13.193 15.4463 13.2775 15.3523C13.3623 15.2583 13.4031 15.1327 13.3899 15.007ZM5.03196 3.26191C5.03196 2.23137 5.87046 1.39287 6.90112 1.39287C7.93165 1.39287 8.77016 2.23137 8.77016 3.26191V3.75046H5.03196V3.26191ZM1.352 14.6071L2.39833 4.64333H4.13909V5.62717C4.13909 5.87368 4.33902 6.07361 4.58552 6.07361C4.83203 6.07361 5.03196 5.87368 5.03196 5.62717V4.64333H8.77016V5.62717C8.77016 5.87368 8.97009 6.07361 9.21659 6.07361C9.4631 6.07361 9.66303 5.87368 9.66303 5.62717V4.64333H11.4038L12.4501 14.6071H1.352Z"
                                        fill="#4E1529"
                                      />
                                    </g>
                                  </g>
                                </g>
                                <defs>
                                  <clipPath id="clip0_117_235">
                                    <rect
                                      width="15"
                                      height="16"
                                      fill="white"
                                      transform="translate(0.410034)"
                                    />
                                  </clipPath>
                                  <clipPath id="clip1_117_235">
                                    <rect
                                      width="15"
                                      height="16"
                                      fill="white"
                                      transform="translate(0.410034)"
                                    />
                                  </clipPath>
                                  <clipPath id="clip2_117_235">
                                    <rect
                                      width="15"
                                      height="15"
                                      fill="white"
                                      transform="translate(0.410034 0.5)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                              {/* <MdOutlineShoppingBag className="rcs_cart_mobile_icon" /> */}
                              {cartlength && cartlength != "0" ? (
                                <span className="rcs_cart_badge">
                                  {cartlength}
                                </span>
                              ) : (
                                ""
                              )}
                            </li>
                          </ul>
                        </div>
                      </Col>
                    ) : isTablet ? (
                      <Col>
                        <div className="rcs_right_side rcs_right_side_mobile1">
                          <ul>
                            <li>
                              <Login />
                            </li>
                            <li
                              onClick={() => {
                                history.push("/cart");
                              }}
                            >
                              <svg
                                className="rcs_cart_mobile_icon"
                                width="19"
                                height="19"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_117_235)">
                                  <g clip-path="url(#clip1_117_235)">
                                    <g clip-path="url(#clip2_117_235)">
                                      <path
                                        d="M13.3899 15.007L12.2498 4.15031C12.2259 3.92304 12.0344 3.75046 11.8058 3.75046H9.66303V3.26191C9.66303 1.73905 8.42398 0.5 6.90112 0.5C5.37814 0.5 4.13909 1.73905 4.13909 3.26191V3.75046H1.9963C1.76776 3.75046 1.57619 3.92304 1.55227 4.15031L0.412214 15.007C0.399053 15.1327 0.439909 15.2583 0.52448 15.3523C0.609166 15.4463 0.729787 15.5 0.856244 15.5H12.9458C13.0723 15.5 13.193 15.4463 13.2775 15.3523C13.3623 15.2583 13.4031 15.1327 13.3899 15.007ZM5.03196 3.26191C5.03196 2.23137 5.87046 1.39287 6.90112 1.39287C7.93165 1.39287 8.77016 2.23137 8.77016 3.26191V3.75046H5.03196V3.26191ZM1.352 14.6071L2.39833 4.64333H4.13909V5.62717C4.13909 5.87368 4.33902 6.07361 4.58552 6.07361C4.83203 6.07361 5.03196 5.87368 5.03196 5.62717V4.64333H8.77016V5.62717C8.77016 5.87368 8.97009 6.07361 9.21659 6.07361C9.4631 6.07361 9.66303 5.87368 9.66303 5.62717V4.64333H11.4038L12.4501 14.6071H1.352Z"
                                        fill="#4E1529"
                                      />
                                    </g>
                                  </g>
                                </g>
                                <defs>
                                  <clipPath id="clip0_117_235">
                                    <rect
                                      width="15"
                                      height="16"
                                      fill="white"
                                      transform="translate(0.410034)"
                                    />
                                  </clipPath>
                                  <clipPath id="clip1_117_235">
                                    <rect
                                      width="15"
                                      height="16"
                                      fill="white"
                                      transform="translate(0.410034)"
                                    />
                                  </clipPath>
                                  <clipPath id="clip2_117_235">
                                    <rect
                                      width="15"
                                      height="15"
                                      fill="white"
                                      transform="translate(0.410034 0.5)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                              {/* <MdOutlineShoppingBag className="rcs_cart_mobile_icon" /> */}
                              {cartlength && cartlength != "0" ? (
                                <span className="rcs_cart_badge">
                                  {cartlength}
                                </span>
                              ) : (
                                ""
                              )}
                            </li>
                          </ul>
                        </div>
                      </Col>
                    ) : (
                      <Col
                        md={4}
                        lg={8}
                        className="d-flex align-items-center justify-content-end"
                      >
                        <div
                          className={
                            "mg_menu_right_side_area " +
                            HeaderStyle.showcase_center_header_right
                          }
                        >
                          <div className="rcs_right_side rcs_right_side_mobile d-none d-lg-block">
                            <div className="oro_Contact_and_searchbox">
                              <div>
                                <a href={contact_number.link}>
                                  <span>Contact Us</span>
                                  {contact_number.text}
                                </a>
                              </div>
                              <div>
                                <Form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    history.push("/search/" + search_text);
                                    window.scrollTo(0, 0);
                                    // setSearch_text("");
                                  }}
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Search for.."
                                    value={search_text}
                                    onChange={(e) =>
                                      setSearch_text(e.target.value)
                                    }
                                  />
                                </Form>
                              </div>
                            </div>
                            <ul>
                              <li className="d-none d-lg-none">
                                {/* <img alt=""
                                onClick={() => setSearch_show(!search_show)}
                                onMouseOver={() => setSearchhover(true)}
                                onMouseLeave={() => setSearchhover(false)}
                                className="rcs_wishlist_icon"
                                src={searchhover ? Magnify1 : Magnify0}
                              /> */}
                                {/* <Modal
                                className={
                                  isScrollValueMoreThanHeaderHeight
                                    ? "rcs_search_modal12"
                                    : "rcs_search_modal"
                                }
                                show={search_show}
                                onHide={() => setSearch_show(false)}
                              > */}
                                <div
                                  className={
                                    setDesktopSearch == true
                                      ? "show " +
                                        " rcs_search_bar " +
                                        HeaderStyle.DesktopSearch
                                      : "close " +
                                        " rcs_search_bar " +
                                        HeaderStyle.DesktopSearch
                                  }
                                >
                                  {setDesktopSearch == true && (
                                    <Form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        history.push("/search/" + search_text);
                                        window.scrollTo(0, 0);
                                        //setSearch_text("");
                                      }}
                                    >
                                      {/* <SearchIcon
                                      onClick={(e) => {
                                        history.push("/search/" + search_text);
                                        setSearch_text("");
                                        
                                      }}
                                    /> */}
                                      <Form.Control
                                        type="text"
                                        placeholder="Search for.."
                                        value={search_text}
                                        onChange={(e) =>
                                          setSearch_text(e.target.value)
                                        }
                                      />
                                      <AiOutlineClose
                                        className={
                                          HeaderStyle.DesktopSearchClose
                                        }
                                        onClick={() =>
                                          setShowDesktopSearch(false)
                                        }
                                      />
                                    </Form>
                                  )}
                                </div>
                                {/* </Modal> */}

                                {/* <svg onClick={() => setSearch_show(!search_show)} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.7112 18.3373L15.2347 13.8554C16.3899 12.3855 17.0878 10.5542 17.0878 8.55422C17.0878 3.83133 13.2611 0 8.54392 0C3.82671 0 0 3.83133 0 8.55422C0 13.2771 3.82671 17.1084 8.54392 17.1084C10.5656 17.1084 12.4188 16.4096 13.8628 15.2289L18.3393 19.7108C18.5319 19.9036 18.7726 20 19.0132 20C19.2539 20 19.4946 19.9036 19.7112 19.7108C20.0963 19.3253 20.0963 18.7229 19.7112 18.3373ZM1.92539 8.55422C1.92539 4.89157 4.88568 1.92771 8.54392 1.92771C12.2022 1.92771 15.1625 4.89157 15.1625 8.55422C15.1625 12.2169 12.2022 15.1807 8.54392 15.1807C4.88568 15.1807 1.92539 12.2169 1.92539 8.55422Z" fill="var(--primary)" />
                              </svg> */}
                                <svg
                                  onClick={() =>
                                    setShowDesktopSearch(!setDesktopSearch)
                                  }
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19.7112 18.3373L15.2347 13.8554C16.3899 12.3855 17.0878 10.5542 17.0878 8.55422C17.0878 3.83133 13.2611 0 8.54392 0C3.82671 0 0 3.83133 0 8.55422C0 13.2771 3.82671 17.1084 8.54392 17.1084C10.5656 17.1084 12.4188 16.4096 13.8628 15.2289L18.3393 19.7108C18.5319 19.9036 18.7726 20 19.0132 20C19.2539 20 19.4946 19.9036 19.7112 19.7108C20.0963 19.3253 20.0963 18.7229 19.7112 18.3373ZM1.92539 8.55422C1.92539 4.89157 4.88568 1.92771 8.54392 1.92771C12.2022 1.92771 15.1625 4.89157 15.1625 8.55422C15.1625 12.2169 12.2022 15.1807 8.54392 15.1807C4.88568 15.1807 1.92539 12.2169 1.92539 8.55422Z"
                                    fill="var(--primary)"
                                  />
                                </svg>

                                <div className={HeaderStyle.Vline}></div>
                              </li>
                              <li className="d-none d-lg-block">
                                <div className="oro_f_text_icons_boox_menu">
                                  <Login />{" "}
                                  {user?.isLogin ? (
                                    " "
                                  ) : (
                                    <span className="oro_header_icon_text_box">
                                      Login
                                    </span>
                                  )}
                                </div>
                              </li>
                              <li
                                className="d-none d-lg-block"
                                onClick={() =>
                                  user?.isLogin
                                    ? history.push("/account/wishlist")
                                    : history.push("/wishlist")
                                }
                              >
                                <div className="oro_f_text_icons_boox_menu">
                                  <svg
                                    className="rcs_wishlist_icon"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_117_227)">
                                      <g clip-path="url(#clip1_117_227)">
                                        <path
                                          d="M14.7656 2.67589C14.0257 1.83642 12.9994 1.37411 11.8756 1.37411C10.2951 1.37411 9.29447 2.31806 8.73337 3.10995C8.5878 3.31544 8.46396 3.52149 8.35999 3.71537C8.25601 3.52149 8.1322 3.31544 7.9866 3.10995C7.4255 2.31806 6.42487 1.37411 4.84436 1.37411C3.72059 1.37411 2.69423 1.83645 1.95437 2.67592C1.24867 3.47672 0.859985 4.54922 0.859985 5.69584C0.859985 6.94398 1.34728 8.10487 2.39353 9.34916C3.3286 10.4613 4.67382 11.6076 6.23157 12.935C6.81203 13.4297 7.41229 13.9412 8.05134 14.5003L8.07053 14.5171C8.15338 14.5897 8.25668 14.6259 8.35999 14.6259C8.46329 14.6259 8.56659 14.5896 8.64944 14.5171L8.66863 14.5003C9.30768 13.9412 9.90794 13.4297 10.4885 12.935C12.0461 11.6076 13.3914 10.4613 14.3264 9.34916C15.3727 8.10484 15.86 6.94398 15.86 5.69584C15.86 4.54922 15.4713 3.47672 14.7656 2.67589ZM9.91837 12.266C9.41798 12.6924 8.903 13.1313 8.35999 13.6033C7.817 13.1313 7.30199 12.6925 6.80151 12.266C3.75291 9.66815 1.73889 7.95191 1.73889 5.69584C1.73889 4.76332 2.04958 3.89719 2.61375 3.25703C3.1844 2.60959 3.97656 2.25302 4.84436 2.25302C6.04931 2.25302 6.82823 2.99535 7.26944 3.61808C7.66521 4.1766 7.87172 4.73962 7.94215 4.95575C8.00107 5.13665 8.16973 5.25909 8.35999 5.25909C8.55024 5.25909 8.7189 5.13665 8.77782 4.95575C8.84825 4.73962 9.05476 4.1766 9.45053 3.61805C9.89174 2.99535 10.6707 2.25302 11.8756 2.25302C12.7434 2.25302 13.5356 2.60959 14.1062 3.25703C14.6704 3.89719 14.9811 4.76332 14.9811 5.69584C14.9811 7.95191 12.9671 9.66815 9.91837 12.266Z"
                                          fill="#4E1529"
                                        />
                                      </g>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_117_227">
                                        <rect
                                          width="15"
                                          height="16"
                                          fill="white"
                                          transform="translate(0.859985)"
                                        />
                                      </clipPath>
                                      <clipPath id="clip1_117_227">
                                        <rect
                                          width="15"
                                          height="15"
                                          fill="white"
                                          transform="translate(0.859985 0.5)"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  <span className="oro_header_icon_text_box">
                                    Wishlist(
                                    {wishlistlength && wishlistlength !== "0"
                                      ? wishlistlength
                                      : "0"}
                                    )
                                  </span>
                                </div>

                                {/* {wishlistlength && wishlistlength !== "0" ? (
                                  <span className="rcs_wish_badge">
                                    {wishlistlength}
                                  </span>
                                ) : (
                                  ""
                                )} */}
                              </li>
                              <li
                                className="d-none d-lg-block"
                                onClick={() => {
                                  history.push("/cart");
                                }}
                              >
                                {/* <img
                              onMouseOver={() => setCarthover(true)}
                              onMouseLeave={() => setCarthover(false)}
                              className="rcs_wishlist_icon"
                              src={carthover ? Cart2 : Cart1}
                            /> */}
                                <div className="oro_f_text_icons_boox_menu">
                                  <svg
                                    className="rcs_wishlist_icon"
                                    width="21"
                                    height="21"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_117_235)">
                                      <g clip-path="url(#clip1_117_235)">
                                        <g clip-path="url(#clip2_117_235)">
                                          <path
                                            d="M13.3899 15.007L12.2498 4.15031C12.2259 3.92304 12.0344 3.75046 11.8058 3.75046H9.66303V3.26191C9.66303 1.73905 8.42398 0.5 6.90112 0.5C5.37814 0.5 4.13909 1.73905 4.13909 3.26191V3.75046H1.9963C1.76776 3.75046 1.57619 3.92304 1.55227 4.15031L0.412214 15.007C0.399053 15.1327 0.439909 15.2583 0.52448 15.3523C0.609166 15.4463 0.729787 15.5 0.856244 15.5H12.9458C13.0723 15.5 13.193 15.4463 13.2775 15.3523C13.3623 15.2583 13.4031 15.1327 13.3899 15.007ZM5.03196 3.26191C5.03196 2.23137 5.87046 1.39287 6.90112 1.39287C7.93165 1.39287 8.77016 2.23137 8.77016 3.26191V3.75046H5.03196V3.26191ZM1.352 14.6071L2.39833 4.64333H4.13909V5.62717C4.13909 5.87368 4.33902 6.07361 4.58552 6.07361C4.83203 6.07361 5.03196 5.87368 5.03196 5.62717V4.64333H8.77016V5.62717C8.77016 5.87368 8.97009 6.07361 9.21659 6.07361C9.4631 6.07361 9.66303 5.87368 9.66303 5.62717V4.64333H11.4038L12.4501 14.6071H1.352Z"
                                            fill="#4E1529"
                                          />
                                        </g>
                                      </g>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_117_235">
                                        <rect
                                          width="15"
                                          height="16"
                                          fill="white"
                                          transform="translate(0.410034)"
                                        />
                                      </clipPath>
                                      <clipPath id="clip1_117_235">
                                        <rect
                                          width="15"
                                          height="16"
                                          fill="white"
                                          transform="translate(0.410034)"
                                        />
                                      </clipPath>
                                      <clipPath id="clip2_117_235">
                                        <rect
                                          width="15"
                                          height="15"
                                          fill="white"
                                          transform="translate(0.410034 0.5)"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  <span className="oro_header_icon_text_box">
                                    Cart(
                                    {cartlength && cartlength !== "0"
                                      ? cartlength
                                      : "0"}
                                    )
                                  </span>
                                </div>

                                {/* {cartlength && cartlength != "0" ? (
                                  <span className="rcs_cart_badge">
                                    {cartlength}
                                  </span>
                                ) : (
                                  ""
                                )} */}
                              </li>
                              <li className="d-none">
                                <Dropdown
                                  className={
                                    "d-inline rcs_dropdown_lang " +
                                    HeaderStyle.showcase_new_lang
                                  }
                                >
                                  <Dropdown.Toggle id="dropdown-autoclose-true">
                                    <Image
                                      src={currencyflag}
                                      alt={currencycode}
                                    ></Image>
                                    <span>{currencycode}</span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {currencydata?.map((val) => (
                                      <Dropdown.Item
                                        onClick={() => {
                                          currencyHandle(
                                            val.currency_code,
                                            val.country_flag,
                                            val.currency_icon
                                          );
                                        }}
                                        href="#"
                                      >
                                        <Image
                                          src={val.country_flag}
                                          alt={val.currency_code}
                                        ></Image>
                                        <span> {val.currency_code}</span>
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Col>
                    )}

                    {isMobile ? (
                      <Col xs={12} className="d-none">
                        <div className="rcs_search_bar mobileSearchBox">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              history.push("/search/" + search_text);
                              window.scrollTo(0, 0);
                              // setSearch_text("");
                            }}
                          >
                            {/* <SearchIcon
                          onClick={(e) => {
                            history.push("/search/" + search_text);
                            setSearch_text("");
                            
                          }}
                        /> */}
                            <svg
                              onClick={(e) => {
                                history.push("/search/" + search_text);
                                setSearch_text("");
                              }}
                              width="16"
                              height="16"
                              style={{ opacity: "0.3" }}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.7112 18.3373L15.2347 13.8554C16.3899 12.3855 17.0878 10.5542 17.0878 8.55422C17.0878 3.83133 13.2611 0 8.54392 0C3.82671 0 0 3.83133 0 8.55422C0 13.2771 3.82671 17.1084 8.54392 17.1084C10.5656 17.1084 12.4188 16.4096 13.8628 15.2289L18.3393 19.7108C18.5319 19.9036 18.7726 20 19.0132 20C19.2539 20 19.4946 19.9036 19.7112 19.7108C20.0963 19.3253 20.0963 18.7229 19.7112 18.3373ZM1.92539 8.55422C1.92539 4.89157 4.88568 1.92771 8.54392 1.92771C12.2022 1.92771 15.1625 4.89157 15.1625 8.55422C15.1625 12.2169 12.2022 15.1807 8.54392 15.1807C4.88568 15.1807 1.92539 12.2169 1.92539 8.55422Z"
                                fill="var(--primary)"
                              ></path>
                            </svg>
                            <Form.Control
                              type="text"
                              placeholder="Search for.."
                              value={search_text}
                              onChange={(e) => setSearch_text(e.target.value)}
                            />
                            {/* <AiOutlineClose
                          onClick={() => setSearch_show(!search_show)}
                        /> */}
                          </Form>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </div>

                <>
                  <div className={HeaderStyle.showcase_bottom_header}>
                    {/* Menu Section */}
                    <div className="rcs_menu menu_list_main">
                      <Container fluid className="rcs_custome_container1">
                        <Row>
                          <nav id="rcs_menu_header_top">
                            <ul className="rcs_header_menu">
                              {menudata?.map((value, index) => (
                                <li
                                  className={
                                    "rcs_top_header_menu " +
                                    HeaderStyle.showcase_top_header_menu
                                  }
                                  onMouseEnter={() => {
                                    setHover(1);
                                    setSubmenu(value);
                                  }}
                                  onMouseLeave={() => setHover(0)}
                                >
                                  <h6 className="1st">
                                    <NavLink to={value?.slug}>
                                      {value?.name}
                                    </NavLink>
                                  </h6>
                                  {value?.design == 5 ? (
                                    <ul
                                      className="rcs_top_header_menu_dropdown"
                                      onMouseEnter={() => setHover(1)}
                                      onMouseLeave={() => setHover(0)}
                                    >
                                      {submenu?.submenu?.map((val) => (
                                        <li className="dir rcs_top_header_menu1">
                                          <span>
                                            <NavLink to={val?.slug}>
                                              {val.name}
                                            </NavLink>
                                          </span>
                                        </li>
                                      ))}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              ))}
                            </ul>
                          </nav>
                        </Row>
                      </Container>
                    </div>
                  </div>
                  {/*mega menu */}
                  <div className="menu_item_main">
                    {submenu?.design != 5 ? (
                      <div
                        onMouseEnter={() => setHover(1)}
                        onMouseLeave={() => setHover(0)}
                        className={
                          hover == "1" && submenu?.submenu?.length
                            ? "rcs_top_header_menu_dropdown mega_menu_content_main visible bw-" +
                              submenu?.name?.replaceAll(" ", "-")
                            : "rcs_top_header_menu_dropdown mega_menu_content_main invisible"
                        }
                      >
                        <div className="rcs_megaMenu d-block">
                          <div className="rcs_megaMenu_inner_top">
                            <div className="rcs_megaMenu_inner">
                              <Container fluid className="p-0">
                                <Row className="w-100 m-auto  justify-content-center">
                                  {submenu?.submenu?.map((val, index) => (
                                    <>
                                      <Col
                                        lg={val?.col}
                                        xl={val?.col}
                                        className="pl-0"
                                      >
                                        {val?.submenu?.map((val1) =>
                                          val1?.design == "1" ? (
                                            <div
                                              className={
                                                "rcs_diamonds_content mj_daimond_d_font bw-" +
                                                val1?.name?.replaceAll(" ", "-")
                                              }
                                            >
                                              <div
                                                className={
                                                  "mega-list-cat " +
                                                  HeaderStyle.megaListCat
                                                }
                                              >
                                                <h2 className="title14 font-bold text-uppercase mtb">
                                                  {val1?.name}
                                                </h2>
                                                <ul>
                                                  {val1?.submenu?.map(
                                                    (val2) => (
                                                      <li>
                                                        {val2?.design == 4 ? (
                                                          <Link
                                                            to="#"
                                                            onClick={() =>
                                                              window.open(
                                                                img_url +
                                                                  val2?.slug,
                                                                "_target"
                                                              )
                                                            }
                                                          >
                                                            {val2?.img_url
                                                              ?.length ? (
                                                              <img
                                                                src={
                                                                  val2?.img_url
                                                                }
                                                                className="img-size"
                                                                alt={
                                                                  val2?.alt_tag
                                                                }
                                                              />
                                                            ) : (
                                                              ""
                                                            )}
                                                            {val2?.name}
                                                          </Link>
                                                        ) : (
                                                          <Link to={val2?.slug}>
                                                            {val2?.img_url
                                                              ?.length ? (
                                                              <img
                                                                src={
                                                                  val2?.img_url
                                                                }
                                                                className="img-size"
                                                                alt={
                                                                  val2?.alt_tag
                                                                }
                                                              />
                                                            ) : (
                                                              ""
                                                            )}
                                                            {val2?.name}
                                                          </Link>
                                                        )}
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              </div>
                                            </div>
                                          ) : val1?.design == "2" ? (
                                            <div
                                              className={
                                                "rcs_diamonds_content dfL-border_rel bw-" +
                                                val1?.name?.replaceAll(" ", "-")
                                              }
                                            >
                                              <h2> {val1?.name} </h2>
                                              <div className="m-auto w-100 row">
                                                {val1?.submenu?.map((val2) => (
                                                  <div className="cust_padd_diamonds_shape col-lg-6">
                                                    <div className="rcs_diamonds_content_inner">
                                                      <Link
                                                        to={val2?.slug}
                                                        onClick={() => {
                                                          setHover(0);
                                                          if (
                                                            val2?.slug.includes(
                                                              "shape"
                                                            )
                                                          ) {
                                                            setRingBuiliderClear(
                                                              true
                                                            );
                                                          }
                                                        }}
                                                      >
                                                        {val2?.img_url
                                                          ?.length ? (
                                                          <img
                                                            src={val2?.img_url}
                                                            alt={val2?.alt_tag}
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                        <p>{val2?.name}</p>
                                                      </Link>
                                                    </div>
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                          ) : val1?.design == "3" ? (
                                            <div className="rcs_diamonds_content">
                                              <Link to={val1?.slug}>
                                                {val1?.img_url?.length ? (
                                                  <img
                                                    src={val1?.img_url}
                                                    alt={val1?.alt_tag}
                                                    className="img-fluid dfl_ddiamond_img"
                                                    style={{
                                                      height: "100%",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </Link>
                                            </div>
                                          ) : (
                                            ""
                                          )
                                        )}
                                        {index < 2 ? (
                                          <div className="dfl_menu_border"></div>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </>
                                  ))}
                                </Row>
                              </Container>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              </Container>
            </div>
            {/* Header Section */}

            {hover == "1" && submenu?.submenu?.length ? (
              <div className="hover_overlayer"></div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Mobile Menu Section */}
        <div className="rcs_menu_mobile d-none">
          <Container>
            <Row>
              <Drawer
                anchor="left"
                open={left}
                onClose={() => toggleDrawer(false)}
              >
                <div
                  role="presentation"
                  // onKeyDown={() => toggleDrawer(false)}
                >
                  <div
                    className={
                      show === true ? "mobile-menu" : "mobile-menu nav-open"
                    }
                  >
                    <div className="mobile-auth-with-currency d-flex align-items-center justify-content-between p-2">
                      {/* <div className="mobile-auth d-flex align-items-center justify-content-start">
                         <span onClick={() => dispatch(loginDrawer(true))}>Login / Signup</span>
                      </div> */}

                      <div className="d-none">
                        <Dropdown
                          className={
                            "d-inline rcs_dropdown_lang " +
                            HeaderStyle.showcase_new_lang
                          }
                        >
                          <Dropdown.Toggle id="dropdown-autoclose-true">
                            <Image
                              src={currencyflag}
                              alt={currencycode}
                            ></Image>
                            <span>{currencycode}</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {currencydata?.map((val) => (
                              <Dropdown.Item
                                onClick={() => {
                                  currencyHandle(
                                    val.currency_code,
                                    val.country_flag,
                                    val.currency_icon
                                  );
                                }}
                                href="#"
                              >
                                <Image
                                  src={val.country_flag}
                                  alt={val.currency_code}
                                ></Image>
                                <span> {val.currency_code}</span>
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div className="inner-mobile-menu">
                      <div className="rcs_search_bar mobileSearchBox px-2 mt-2">
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            history.push("/search/" + search_text);
                            window.scrollTo(0, 0);
                            setSearch_text("");

                            toggleDrawer(false);
                          }}
                        >
                          {/* <SearchIcon
                          onClick={(e) => {
                            history.push("/search/" + search_text);
                            setSearch_text("");
                            
                          }}
                        /> */}
                          <svg
                            onClick={(e) => {
                              history.push("/search/" + search_text);
                              setSearch_text("");
                            }}
                            width="16"
                            height="16"
                            style={{ opacity: "0.3" }}
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.7112 18.3373L15.2347 13.8554C16.3899 12.3855 17.0878 10.5542 17.0878 8.55422C17.0878 3.83133 13.2611 0 8.54392 0C3.82671 0 0 3.83133 0 8.55422C0 13.2771 3.82671 17.1084 8.54392 17.1084C10.5656 17.1084 12.4188 16.4096 13.8628 15.2289L18.3393 19.7108C18.5319 19.9036 18.7726 20 19.0132 20C19.2539 20 19.4946 19.9036 19.7112 19.7108C20.0963 19.3253 20.0963 18.7229 19.7112 18.3373ZM1.92539 8.55422C1.92539 4.89157 4.88568 1.92771 8.54392 1.92771C12.2022 1.92771 15.1625 4.89157 15.1625 8.55422C15.1625 12.2169 12.2022 15.1807 8.54392 15.1807C4.88568 15.1807 1.92539 12.2169 1.92539 8.55422Z"
                              fill="var(--primary)"
                            ></path>
                          </svg>
                          <Form.Control
                            type="text"
                            placeholder="Search for.."
                            value={search_text}
                            onChange={(e) => setSearch_text(e.target.value)}
                          />
                          {/* <AiOutlineClose
                          onClick={() => setSearch_show(!search_show)}
                        /> */}
                        </Form>
                      </div>

                      <Accordion defaultActiveKey="0">
                        {/* <Accordion.Item eventKey="0">
                                <Accordion.Header><Link to="#"><span className='auth'><img src={User} alt="" /></span> LOGIN/SIGNUP</Link></Accordion.Header>
                            </Accordion.Item> */}
                        {menudata?.map((val, index) => (
                          <Accordion.Item eventKey={index}>
                            <Accordion.Header
                              className={`${
                                val.name === "Custom Design" ||
                                val.name === "Watches" ||
                                val.name === "About us" ||
                                val.name === "Services"
                                  ? "no_button"
                                  : ""
                              }`}
                            >
                              <Link
                                to={val.slug}
                                onClick={() => toggleDrawer(false)}
                              >
                                {val.name}
                              </Link>
                            </Accordion.Header>
                            <Accordion.Body>
                              {val?.submenu?.map((val1) =>
                                val1?.submenu?.map((val2) =>
                                  val2?.design == 3 ? (
                                    <img
                                      className="w-100 mt-2"
                                      onClick={() => history.push(val2?.slug)}
                                      src={val2?.img_url}
                                      alt={val2?.alt_tag}
                                    />
                                  ) : (
                                    <>
                                      <h2 className="title">{val2?.name}</h2>
                                      <ul
                                        className={
                                          val2?.design == 2
                                            ? "two-col-list"
                                            : "single-col-list"
                                        }
                                      >
                                        {val2?.submenu?.map((val3) => (
                                          <li>
                                            <Link
                                              onClick={() => {
                                                toggleDrawer(false);
                                                setRingBuiliderClear(true);
                                              }}
                                              to={val3.slug}
                                            >
                                              {val3?.img_url ? (
                                                <img
                                                  src={val3?.img_url}
                                                  alt={val3?.alt_tag}
                                                />
                                              ) : (
                                                ""
                                              )}
                                              {val3?.name}
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </>
                                  )
                                )
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}

                        <Accordion.Item eventKey="7" className="contactDetail">
                          <Accordion.Header>Contact us</Accordion.Header>
                          <div className="contact_detail">
                            <ul>
                              {/* <li>
                                <a
                                  onClick={() => setShow(false)}
                                  target="_blank"

                                href={"https://api.whatsapp.com/send?phone=" + contact_number.chat +  " &amp;text=Hi " + client_name.c_name + "! I have a few queries. Could you please help me out?"}
                                >
                                  <span>
                                    <BsFillChatFill />
                                  </span>
                                  Chat
                                </a>
                              </li> */}
                              <li>
                                <a
                                  onClick={() => {
                                    setShow(false);
                                    window.location.href = contact_number.link;
                                  }}
                                  href="#"
                                >
                                  <span>
                                    <BsFillTelephoneFill />
                                  </span>
                                  Call
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={(e) => {
                                    setShow(false);
                                    window.location.href = Email_details.link;
                                  }}
                                >
                                  <span>
                                    <IoMdMail />
                                  </span>
                                  Email
                                </a>
                              </li>
                              <li onClick={() => toggleDrawer(false)}>
                                <Link
                                  onClick={() => setShow(false)}
                                  to="/contact"
                                >
                                  <span>
                                    <IoMdContact />
                                  </span>
                                  Contact
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </Drawer>
            </Row>
          </Container>
        </div>
        {/* Mobile Menu Section */}
      </div>
    </>
  );
};
export default MegaMenu;
