import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import CustomImg from "../../../../Assets/images/oro-img/rolex.webp";
import Heading from "../ui/Heading";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Je_welryspelling } from "../../../../Helpers/request";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PreownedRolex = () => {
    const history = useHistory();
  return (
    <section className="custom-design pb-0">
      <Container fluid className="showcase-container">
        <Row className="align-items-center">
          <Col md={6} xs={12} className="text-center pr-lg-0">
            <Image src={CustomImg} alt="Custom Design Jewellery " />
          </Col>

          <Col md={6} xs={12} >
            <Heading className="mb-2 text-center">
              <span>Pre-Owned Rolex</span>
              <p>
              Shop our curated collection of pre-owned Rolex watches,
all authenticated and inspected for quality. Experience timeless
luxury at unbeatable prices.
              </p>
            </Heading>

            <div className="text-center">
              <button onClick={()=>history.push("/watches")} className="oro_btn_shop_now ">
                  shop now
               </button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PreownedRolex;
